import React from "react";
import cn from "classnames";
import {
  NoJs,
  Theme,
  Header,
  Footer,
  Splashscreen,
  Favicon,
  ToolboxAside,
  Notification
} from "~components";
import * as styles from "./styles.module.scss";
import "~node_modules/modern-normalize/modern-normalize.css";
import "~styles/global.css";

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children, className }: IProps) => {
  return (
    <>
      <Favicon />
      <Theme />

      <Header />
      <ToolboxAside />

      <div className={cn(styles.container, className)}>{children}</div>

      <Notification />

      <Footer />
      <Splashscreen />

      <NoJs />
    </>
  );
};

export default Layout;
