// extracted by mini-css-extract-plugin
export var allLinksHorizontal = "styles-module--allLinksHorizontal--742c5";
export var container = "styles-module--container--1b805";
export var desktop = "1340px";
export var flag = "styles-module--flag--cdeb8";
export var footerText = "styles-module--footerText--84547";
export var giant = "2200px";
export var interactionButton = "styles-module--interactionButton--bf884";
export var interactionButtonIcon = "styles-module--interactionButtonIcon--22931";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--512c0";
export var linkListGrid = "styles-module--linkListGrid--744e9";
export var linkListItem = "styles-module--linkListItem--04abd";
export var linkListMobileLeftCol = "styles-module--linkListMobileLeftCol--80233";
export var linkListMobileRightCol = "styles-module--linkListMobileRightCol--6c453";
export var mobile = "400px";
export var nav = "styles-module--nav--851c1";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var visible = "styles-module--visible--d3a2c";
export var wordmark = "styles-module--wordmark--04cbc";